declare var global: any;

export let globalScope: any;

if (typeof window !== 'undefined') {
    globalScope = window;
} else if (typeof global !== 'undefined') {
    globalScope = global;
} else {
    throw new Error('No accessible global scope');
}
