import {
    _htmlElementNames,
    Extension, /* IComponent, */ ICompositeComponent, IElementComposerFn,
    IMiddlewareExtender, TComponent
} from '@lardy/core';
import { withMuiComponent } from './decorators/withMuiComponent';
import { withMuiStyles } from './decorators/withStyles';
import { IStyleDataFn } from './interfaces';

export class MuiFragment extends Extension {

    public constructor(self: ICompositeComponent) {
        super(self);

        self.define('elementTransforms', withMuiComponent);
        self.define('elementIndex', this.getElementIndex);
    }

    public style<XS extends object>(style: IStyleDataFn<this, XS>) {
        const self = this.getSelf();

        self.styleNames = [...self.styleNames, ...Object.keys(style({} as any))];

        self.decorate(withMuiStyles(style));

        return self as unknown as ReturnThis<this, XS>;
    }

    private getElementIndex = ($: IElementComposerFn<any, any>) => {
        const names = this.getSelf().styleNames;

        _htmlElementNames.forEach((key) => {
            Object.defineProperty($, key, {
                get() {
                    return names.reduce((elements, name) => ({
                        ...elements,
                        [name]: $(key, name)
                    }), {});
                }
            });
        });
    }
}

export type ReturnThis<This, XS> = This extends TComponent<
    infer P,
    infer S,
    infer OP,
    infer EX,
    infer SX,
    infer XP
> ? TComponent<P, S | keyof XS, OP, EX, SX & { __global: {} }, XP> : TComponent;

const constructor = <P, S, OP, EX, SX, XP>(self: ICompositeComponent<P, S, OP, EX, SX, XP>) => {
    return new MuiFragment(self);
};

(constructor as any).__middlewareRole = 'extender';

const mui = constructor as IMiddlewareExtender<typeof constructor>;

export { mui };
