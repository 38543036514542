import {
    createInjectionMiddleware, IMiddlewareDecorator,
    truncateDisplayName
} from '@lardy/core';
import { createMuiTheme, Theme, ThemeOptions } from '@material-ui/core/styles';
import StylesProvider, { StylesProviderProps } from '@material-ui/styles/StylesProvider';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import useTheme from '@material-ui/styles/useTheme';
import { Omit } from '@material-ui/types';
import { ComponentType, createElement } from 'react';
import { IMuiTheme } from './interfaces';

export const decorator: IMiddlewareDecorator<IMuiTheme> = (
    themeOptions: ThemeOptions,
    stylesProviderProps?: Omit<StylesProviderProps, 'children'>
) =>
    (getProvider: (store: IMuiTheme) => ComponentType<any>) => {
        return <P>(Component: ComponentType<P>) => {
            const theme = createMuiTheme(themeOptions);

            const store = {
                get theme() {
                    return useTheme<Theme>();
                }
            };

            const Provider = getProvider(store);

            const MuiInnerThemeProvider = (props: P) => {
                return createElement(
                    Provider,
                    {},
                    createElement(
                        StylesProvider,
                        {
                            ...(stylesProviderProps || {}),
                            children: createElement(
                                ThemeProvider,
                                { theme } as any,
                                createElement(Component, props)
                            )
                        }
                    )
                );
            };

            MuiInnerThemeProvider.displayName = truncateDisplayName(
                Component.displayName,
                'MuiInnerThemeProvider'
            );

            return MuiInnerThemeProvider;
        };
    };

export const muiThemeContext = createInjectionMiddleware<IMuiTheme>('MuiTheme', decorator);

export const themeProvider = (
    themeOptions: ThemeOptions,
    stylesProviderProps?: Omit<StylesProviderProps, 'children'>
) => muiThemeContext.provider(
    themeOptions,
    stylesProviderProps
);

const hook = muiThemeContext.hook;

export const theme = hook('theme', 'theme');
