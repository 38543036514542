import { Context, createContext } from 'react';
import { globalScope } from './global';
import { IStyleSheet } from './interfaces';

export let MuiContext: Context<IStyleSheet<any, any>>;

if (globalScope.__LARDY_MUI__CONTEXT__MUI_CONTEXT__ === undefined) {
    globalScope.__LARDY_MUI__CONTEXT__MUI_CONTEXT__ = createContext<IStyleSheet<any, any>>({});
}

MuiContext = globalScope.__LARDY_MUI__CONTEXT__MUI_CONTEXT__;
