
import { truncateDisplayName } from '@lardy/core';
import makeStyles from '@material-ui/styles/makeStyles';
import { observer } from 'mobx-react-lite';
import { ClassicComponentClass, ComponentClass, createElement, SFC } from 'react';
import { MuiContext } from '../context';
import { prepareInput } from '../helpers';
import { IStyleDataFn, IStyleSheet } from '../interfaces';

export const withMuiStyles = <S extends IStyleDataFn<any, object>>(styleFn: S) => {
    return <P>(Component: SFC<P> | ComponentClass<P> | ClassicComponentClass<P>) => {
        const WithMuiStyles = observer((props: P) => {
            const styles = styleFn(props);

            (styles as any).Base = (styles as any).Base || {};

            const stylesheet = Object.keys(styles).reduce((sheet, key) => ({
                ...sheet,
                [key]: makeStyles(prepareInput(styles[key]))
            }), {} as IStyleSheet<any, any>);

            return createElement(MuiContext.Provider, {
                value: stylesheet as any
            }, createElement(Component, props));
        });

        WithMuiStyles.displayName = truncateDisplayName(
            Component.displayName,
            'WithMuiStyles'
        );

        return WithMuiStyles;
    };
};
