import { IElementTransformProps, truncateDisplayName } from '@lardy/core';
import { ComponentType, createElement, forwardRef, useContext } from 'react';
import { MuiContext } from '../context';

const withMuiComponent = ({
    name
}: IElementTransformProps) => <P>(Component: ComponentType<P>) => {
    const MuiInnerComponent = forwardRef<HTMLElement, P>((props, ref) => {
        const stylesheet = useContext(MuiContext);

        const useStyles = stylesheet[name];

        const innerProps = {...(props as object)};

        if (useStyles) {

            const classes = useStyles(props);

            const className = Object.keys(classes).map((key) => classes[key]).join(' ');

            (innerProps as any).className = [ (innerProps as any).className, className ].join(' ').trim();
            (innerProps as any).classes = classes;

        } else {
            console.warn('No stylesheet found: ' + name);
        }

        (innerProps as any).ref = ref;

        return createElement(Component, innerProps as any);
    });

    (MuiInnerComponent as any).displayName = truncateDisplayName(
        Component.displayName,
        'MuiInnerComponent'
    );

    return MuiInnerComponent;
};

export { withMuiComponent };
