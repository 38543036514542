import { CreateCSSProperties, Styles } from '@material-ui/styles/withStyles';
import { IStyleInput } from '../interfaces';

export function prepareInput<A, B extends object, C extends string>(input: Styles<A, B, C>): Styles<A, B, C>;
export function prepareInput<P extends object>(
    input: CreateCSSProperties<P> | ((props: P) => CreateCSSProperties<P>)
): Styles<unknown, P, 'root'>;
export function prepareInput(input: IStyleInput<any>) {
    const hasCssProperties = typeof input !== 'function' && Object.keys(input).find((key) => {
        return typeof input[key] !== 'object';
    });

    if (hasCssProperties) {
        return ({
            root: input
        } as unknown) as Styles<unknown, {}, 'root'>;
    }

    return input;
}
